import React from 'react';
import OfferEnable from './OfferEnable';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserData, updateUserLoginStatus } from '../../store/userSlice';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import AdminOfferCreator from './CreateOffer'
export default function AGManagerCorner(props) {
  const user = useSelector(state=>state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const logout = () => {
    // setStep('success'); 
    localStorage.removeItem('jwtToken');
    dispatch(updateUserData({ userData: { mobile: null } }))
    dispatch(updateUserLoginStatus({ isLoggedIn: false }))
    navigate('/ag-manager-login/')
  }

  return (
    <div>
      <div className='d-flex'>
        {user?.isLoggedIn && user?.userData?.mobile && (
          <><Typography variant="h6" display='inline' sx={{ ml: 3, mr: 3 }}>
            Logged in with: {user?.userData.mobile}
          </Typography>
          <Button variant='outlined' onClick={logout} >Logout</Button>
          <Link to='/ag-admin/create-offer'><Button variant='outlined' className='ms-3' >Create</Button></Link>
          <Link to='/ag-admin/enable-offer'><Button variant='outlined' className='ms-3' >Enable</Button></Link>
          </>
        )}
      </div>
      <Routes>
        <Route path='create-offer/*' element={<div><AdminOfferCreator /></div>} />
        <Route path='enable-offer/*' element={<div><OfferEnable /></div>} />
        <Route path='*' element={<div>Hello</div>} />
      </Routes>
      

    </div>
  );
}
